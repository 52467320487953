import AppConfig from '../../utils/appconfig';
import { Navbar } from '../../components/navbar';
import { Footer } from '../../components/footer';
import { useEffect } from 'react';
import { SectionTitle } from '../../components/sectionTitle';
import { OWydarzeniuBgImageSection } from './components/oWydarzeniuBgImageSection';
import { scrollBodyToTop } from '../../utils/utils';

import tireTracks from '../../images/tire-track.png';
import beginner from '../../images/beginner.jpg';
import bgImageSection from '../../images/hard-adv-4.jpg';

const currentPage: string = 'o-wydarzeniu';

export function OWydarzeniu() {
  useEffect(() => {
    document.title = `O wydarzeniu - ${AppConfig.baseTabTitle}`;

    scrollBodyToTop();
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <div className="content-wrapper o-wydarzeniu__content-wrapper">
        <div className="o-wydarzeniu-section-1">
          <SectionTitle title={<><span className="text-color--primary">HARD ADV WEEKEND</span> - III EDYCJA</>} customClassName={'section-title__wrapper--home'} />

          <p>Jak co roku zlot motocyklowy HARD ADV WEEKEND dedykowane jest dla tych, którzy szukają prawdziwych wyzwań oraz poszukują mocnych emocji i chcą przekroczyć swoje granice. Musisz jednak być odpowiednio przygotowanym kondycyjnie.</p>
          
          <p className='text-weight--bold'>Będziecie mieli okazję przetestować swoje umiejętności, pokonywać różnorodne przeszkody i cieszyć się niesamowitymi widokami, których dostarczą wam przygotowane przez nas trasy.</p>
          
          <p>W ramach wydarzenia zdecydowaliśmy podzielić uczestników na trzy grupy w zależności od umiejętności i doświadczenia w jeździe off-road.</p>
          
          <p>Podział na grupy poziomowe ma na celu zapewnienie bezpieczeństwa i odpowiedniego wyzwania dla każdego uczestnika. Jako organizatorzy musimy dostosować trudność tras dla każdej grupy, aby zapewnić optymalne warunki dla wszystkich uczestników.</p>
        </div>
      </div>

      <OWydarzeniuBgImageSection backgroundImage={bgImageSection} />

      <div className="o-wydarzeniu-section-3">
        <img src={beginner} alt="Motocyklista na motocyklu enduro" className="o-wydarzeniu-section-3__image" loading="lazy" />

        <div className="o-wydarzeniu-section-3__text">
          <SectionTitle title={<>HARD ADV <span className="text-color--primary">BEGINNERS</span></>} customClassName={'section-title__wrapper--home'} />

          <p>Jest to klasa dla osób, które lubią jeździć drogami utwardzonymi i szutrami o łatwej skali trudności a przy tym chcą podziwiać widoki i rozkoszować się bezpieczną jazdą.</p>
          
          <p>Dzięki profesjonalnej szkole motocyklowej ADV Academy która udzieli nam wsparcia.</p>
          
          <p>Będzie dostępne szkolenie dla chętnych w ramach uczestnictwa, w zakresie panowania nad ciężkim motocyklem podczas jazdy po drogach gruntowych i terenie przygodnym.</p> 
          
          <p>Zapewnienie uczestnikom niezbędnych umiejętności i technik, które pozwolą im bezpieczniej poruszać się w trudniejszym terenie jest dla nas priorytetem.</p> 
          
          <p>Szkolenie jest skonstruowane w taki sposób, aby uczestnicy mogli stopniowo rozwijać swoje umiejętności i zwiększać swoje zaufanie do jazdy na ciężkim motocyklu w trudnych warunkach.</p>
        </div>
      </div>

      <div className="o-wydarzeniu__tire-tracks--wrapper">
        <img src={tireTracks} alt="Ślady opon terenowych" className="o-wydarzeniu__tire-tracks" loading="lazy" />
      </div>

      <Footer currentPage={currentPage} />
    </div>
  );
}
